<div class="card">
    <div class="card-body" *ngIf="showSuccessMessage">
        <div class="row mb-3">
            <div class="col-8 m-auto">
                <div class="row">
                    <p>
                        Registration request submitted. Thank you for your registration. Your request has been sent to
                        the
                        responsible
                        administrator. The verification process usually takes about 48 hours. You will be contacted via
                        e-mail upon completion.
                    </p>
                    <div class="col-12 text-center">
                        <i class="success-icon element-checked text-primary"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body" *ngIf="!showSuccessMessage">
        <div class="row mb-3">
            <div class="col-8 m-auto">
                <div class="row">
                    <h3 *ngIf="!siteParam">Partner Portal Registration</h3>
                    <h3 *ngIf="siteParam === 'oem'">OEM Portal Registration</h3>
                    <h3 *ngIf="siteParam === 'cccommunity'">Desigo CC Ecosystem Registration</h3>

                    <p *ngIf="!siteParam">
                        Access to the Portal is reserved exclusively for Siemens Buildings certified partners. If you
                        are
                        not yet a partner and would like to become one, please fill in the form at the bottom of this
                        <a href="https://www.siemens.com/global/en/products/buildings/partners/partner-network.html#JointhePartnerProgramofSiemensBuildingProducts"
                            target="_blank" rel="noopener">website</a>
                        or contact your local Siemens branch. To access the portal, please register using the
                        following form:
                    </p>
                    <p *ngIf="siteParam === 'oem'">
                        Access to the OEM Portal is reserved exclusively for Siemens Buildings OEM partners. If you are
                        not
                        yet a partner and would like to become one, please click on “Contact us” on this <a
                            href="https://www.siemens.com/global/en/products/buildings/partners/oem-partnerships.html"
                            target="_blank" rel="noopener">website</a> or contact
                        your local Siemens branch. To access the portal, please register using the following form:
                    </p>
                    <p *ngIf="siteParam === 'cccommunity'">
                        Desigo CC Ecosystem has been created to enable the dynamic and innovative development of
                        building
                        management, to gather
                        information and to exchange ideas, products and services. As a community member you will profit
                        from
                        a number of
                        benefits when working with Desigo CC, including learning, exchanging information and
                        collaborating.
                    </p>
                </div>
            </div>
        </div>



        <form [formGroup]="form" #f="ngForm" (ngSubmit)="submit()">
            <div class="row mb-3">
                <div class="col-8 m-auto">
                    <div class="row">
                        <div class="col-6 form-floating sie-floating-input">
                            <input type="text" class="form-control" id="firstNameInput" formControlName="firstName"
                                placeholder="{{'first.name' | translate}}" [ngClass]="validate('firstName')">
                            <label for="firstNameInput">* {{'first.name' | translate}}</label>
                            <i class="invalid-icon element-cancel-filled"></i>
                            <div class="invalid-feedback">{{'FORM.FIRST_NAME_REQUIRED' | translate}}</div>
                        </div>

                        <div class="col-6 form-floating sie-floating-input">
                            <input type="text" class="form-control" id="lastNameInput" formControlName="lastName"
                                placeholder="{{'last.name' | translate}}" [ngClass]="validate('lastName')">
                            <label for="lastNameInput">* {{'last.name' | translate}}</label>
                            <i class="invalid-icon element-cancel-filled"></i>
                            <div class="invalid-feedback">{{'FORM.LAST_NAME_REQUIRED' | translate}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-8 m-auto">
                    <div class="row">
                        <div class="col-6 form-floating sie-floating-input">
                            <input type="text" class="form-control" id="companyInput" formControlName="company"
                                placeholder="{{'company' | translate}}" [ngClass]="validate('company')">
                            <label for="companyInput">* {{'company' | translate}}</label>
                            <i class="invalid-icon element-cancel-filled"></i>
                            <div class="invalid-feedback">{{'FORM.COMPANY_REQUIRED' | translate}}</div>
                        </div>

                        <div class="col-6 form-floating sie-floating-input">
                            <input type="text" class="form-control" id="emailInput" formControlName="email"
                                placeholder="{{'company.email' | translate}}" [ngClass]="validate('email')">
                            <label for="emailInput">* {{'company.email' | translate}}</label>
                            <i class="invalid-icon element-cancel-filled"></i>
                            <div class="invalid-feedback">{{'FORM.EMAIL_REQUIRED' | translate}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-8 m-auto">
                    <div class="row">
                        <div class="col-6 form-floating">
                            <select type="text" class="form-select sie-floating-select text-white" id="countryInput"
                                (ngModelChange)="countryChanged($event)" formControlName="country"
                                [ngClass]="validate('country')">
                                <option *ngFor="let item of countriesOptions" [value]="item.id">
                                    {{ item.title | translate }}
                                </option>
                            </select>
                            <label for="countryInput">* {{'country' | translate}}</label>
                            <i class="invalid-icon element-cancel-filled"></i>
                            <div class="invalid-feedback">{{'FORM.COUNTRY_REQUIRED' | translate}}</div>
                        </div>

                        <div class="col-6 form-floating sie-floating-input" *ngIf="!profileFound">
                            <input type="text" class="form-control" id="cityInput" formControlName="city"
                                placeholder="{{'city' | translate}}">
                            <label for="cityInput">{{'city' | translate}}</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-3" *ngIf="!profileFound">
                <div class="col-8 m-auto">
                    <div class="row">
                        <div class="col-6 form-floating sie-floating-input">
                            <input type="text" class="form-control" id="streetInput" formControlName="street"
                                placeholder="{{'street' | translate}}">
                            <label for="streetInput">{{'street' | translate}}</label>
                        </div>

                        <div class="col-6 form-floating sie-floating-input">
                            <input type="text" class="form-control" id="zipInput" formControlName="zip"
                                placeholder="{{'zip' | translate}}">
                            <label for="zipInput">{{'zip' | translate}}</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-3" *ngIf="!profileFound">
                <div class="col-8 m-auto">
                    <div class="row">
                        <div class="col-6 form-floating sie-floating-input">
                            <ngx-intl-tel-input [cssClass]="'form-control text-white form select sie-floating-select'"
                                [preferredCountries]="[]" #ngxIntlTelInput [enableAutoCountrySelect]="false"
                                [enablePlaceholder]="true" [searchCountryFlag]="true"
                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                [selectFirstCountry]="false" [maxLength]="15" [separateDialCode]="separateDialCode"
                                [phoneValidation]="true" name="phoneInput" formControlName="phoneObj"
                                [ngClass]="validate('phoneObj')"></ngx-intl-tel-input>
                            <div class="invalid-feedback">{{'FORM.INVALID_PHONE' | translate}} </div>
                            <!-- <pre>{{ form.controls['phoneObj'].errors | json }}</pre> -->
                        </div>

                        <div class="col-6 form-floating sie-floating-input">
                            <input type="text" class="form-control" id="departmentInput" formControlName="department"
                                placeholder="{{'department' | translate}}">
                            <label for="departmentInput">{{'department' | translate}}</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-3" *ngIf="!profileFound">
                <div class="col-8 m-auto">
                    <div class="row">
                        <div class="col-6 form-floating sie-floating-input">
                            <input type="password" class="form-control" id="passwordInput" formControlName="password"
                                placeholder="{{'password' | translate}}" [ngClass]="validate('password')">
                            <label for="passwordInput">* {{'password' | translate}}</label>
                            <i class="invalid-icon element-cancel-filled"></i>
                            <div class="invalid-feedback">
                                <span *ngIf="!form.get('password')?.value">{{'FORM.PASSWORD_REQUIRED' |
                                    translate}}</span>
                                <span *ngIf="form.get('password')?.value">{{'FORM.PASSWORD_STRENGTH' |
                                    translate}}</span>
                            </div>
                        </div>

                        <div class="col-6 form-floating sie-floating-input">
                            <input type="password" class="form-control" id="retypePasswordInput"
                                formControlName="retypePassword" placeholder="{{'confirm.password' | translate}}"
                                [ngClass]="validate('retypePassword')">
                            <label for="retypePasswordInput">* {{'confirm.password' | translate}}</label>
                            <i class="invalid-icon element-cancel-filled"></i>
                            <div class="invalid-feedback">
                                <span *ngIf="!form.get('retypePassword')?.value">{{'FORM.RETYPE_PASSWORD_REQUIRED' |
                                    translate}}</span>
                                <span *ngIf="form.get('retypePassword')?.value">{{'FORM.RETYPE_PASSWORD_NO_MATCH' |
                                    translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-8 m-auto">
                    <div class="row">
                        <p>{{'password.warning' | translate}}</p>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-8 m-auto">
                    <div class="row">
                        <hr class="hr" />
                    </div>
                </div>
            </div>


            <div class="row mb-3">
                <div class="col-8 m-auto">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 form-floating sie-floating-input" *ngIf="siteParam !== 'cccommunity'"
                            formGroupName="portalSection">
                            <p class="mb-3">{{'portal.section.message' | translate}}</p>

                            <div class="form-check" *ngIf="!siteParam">
                                <input id="buildingAutomationInput" class="form-check-input"
                                    formControlName="buildingAutomation" type="checkbox"
                                    [ngClass]="validate('portalSection')" />
                                <label for="buildingAutomationInput" class="form-check-label"> Building
                                    Automation </label>
                                <i class="invalid-icon element-cancel-filled"></i>
                            </div>

                            <div class="form-check" *ngIf="!siteParam">
                                <input id="fireSafetyInput" class="form-check-input" formControlName="fireSafety"
                                    type="checkbox" [ngClass]="validate('portalSection')" />
                                <label for="fireSafetyInput" class="form-check-label"> Fire Safety </label>
                                <i class="invalid-icon element-cancel-filled"></i>
                            </div>

                            <div class="form-check" *ngIf="siteParam === 'oem'">
                                <input id="climatixOemInput" class="form-check-input" formControlName="climatixOem"
                                    type="checkbox" [ngClass]="validate('portalSection')" />
                                <label for="climatixOemInput" class="form-check-label"> Climatix OEM </label>
                                <i class="invalid-icon element-cancel-filled"></i>
                            </div>

                            <div class="form-check" *ngIf="siteParam === 'oem'">
                                <input id="combustionInput" class="form-check-input" formControlName="combustion"
                                    type="checkbox" [ngClass]="validate('portalSection')" />
                                <label for="combustionInput" class="form-check-label"> Combustion </label>
                                <i class="invalid-icon element-cancel-filled"></i>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-sm-12 form-floating sie-floating-input" *ngIf="siteParam === 'cccommunity'"
                            formGroupName="portalSection">
                        </div>

                        <div class="col-lg-6 col-md-12 col-sm-12 form-floating sie-floating-input">
                            <div class="form-control-has-icon w-100">
                                <re-captcha formControlName="recaptcha" class="form-control captcha-form"
                                    [ngClass]="validate('recaptcha')"></re-captcha>
                                <i class="invalid-icon element-cancel-filled"></i>
                                <div class="invalid-feedback p-l-25">{{'FORM.RECAPTCHA_REQUIRED' | translate}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-8 m-auto">
                    <div class="row">
                        <!-- <div class="col-8"></div> -->
                        <div class="col-12 m-auto div-align-right">
                            <button class="sie-button sie-button-secondary" type="reset">{{'clear' |
                                translate}}</button>
                            <button class="sie-button sie-button-primary mx-3" type="submit">{{'submit' |
                                translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>