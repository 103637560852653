<div class="root">
  <div class="main">
    <a [routerLink]="'/home'" class="logo-box">
      <img class="logo" src="assets/SiemensLogo.svg" alt="Logo" />
    </a>
    <div class="app-name">Partner Portal</div>

    <div class="language-box">
      <div class="language-item" *ngIf="!profileFound">
        <svg role="img" class="icon">
          <use xlink:href="assets/svg/symbol-defs.svg#sie-Login"></use>
        </svg>
        <a href="#" class="me-3" (click)="signIn()">{{ 'sign.in' | translate }}</a>
      </div>

      <div class="language-item">
        <svg role="img" class="icon">
          <use xlink:href="assets/svg/symbol-defs.svg#sie-Language"></use>
        </svg>
        <a href="#" (click)="onChangeLanguage($event)">{{ currentLanguage }}</a>
      </div>
    </div>
  </div>

  <nav class="navigation" app-navigation></nav>
</div>