import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../shared/services/auth.service';
import { getLocalItem, setLocalItem } from '../shared/utils/local-storage-service';

@Component({
  selector: 'app-header-new',
  templateUrl: './header-new.component.html',
  styleUrls: ['./header-new.component.scss']
})
export class HeaderNewComponent implements OnInit {
  currentLanguage: string | null = '';
  profileFound = false;

  constructor(private translate: TranslateService, private authService: AuthService) {
    const language = getLocalItem('userLangPreference') || 'en';
    if (language === 'en') {
      this.currentLanguage = 'English';
    } else {
      this.currentLanguage = 'Deutch';
    }
  }

  ngOnInit(): void {
    this.getProfile();
  }

  onChangeLanguage(e: Event) {
    e.preventDefault();

    let targetLang = 'en';
    if (this.currentLanguage == 'English') {
      targetLang = 'de';
    }

    this.translate.use(targetLang).subscribe(() => {
      setLocalItem('userLangPreference', targetLang);
      window.location.reload();
    });
  }

  signIn() {
    open(`${environment.serverUrl}/auth`, '_self');
  }

  getProfile(): void {
    this.authService.getProfile().subscribe({
      next: response => {
        console.log(`Profile response: ${JSON.stringify(response)}`);

        if (response?.id) {
          this.profileFound = true;
        }
      },
      error: err => {
        console.error(err);
      }
    });
  }
}
